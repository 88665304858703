.swiper{
    overflow: hidden;
    .swiper-pagination{
        bottom:.8rem!important;
        .swiper-pagination-bullet{
            background: none;
            width: 1.2rem;
            height: 1.2rem;
            border: 1px solid #0a5e9d!important;
            overflow: hidden;
            text-indent: -9999px;
            padding: 0;
            border-radius: 50%;
            display: inline-block;
            margin-left: .5rem;
            margin-right: .5rem;
            cursor: pointer;
            &.swiper-pagination-bullet-active{
                background-color: #0a5e9d!important;
                color: #fff!important;
            }
            &:hover{
                background-color: #0a5e9d!important;
                color: #fff!important;
            }
        }
    }
    &[data-cmp-pagination='true']{
        padding-bottom: 2rem !important;
        @media screen and (max-width: 1024px) {
            padding-bottom: 3rem !important;
        }
    }
}
.carousel:not(.kv-carousel){
    margin-right: auto !important;
    margin-left: auto !important;
    @media screen and (min-width: 768px) {
        width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
        width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
        width: 123.8rem !important;
    }
}
.swiper-button-prev-clone,.swiper-button-next-clone{
    @media screen and (max-width: 992px) {
        display:none;
    }
}
.kv-carousel{
    .swiper{
        height:65.1rem;
        .kvitem{
            &>a{
                width: 100%;
                height:65.1rem;
                background-position: 50% 0px;
                background-repeat: no-repeat;
                background-size: cover;
                padding:8rem 1.5rem 30rem 1.5rem;
                box-sizing: border-box;
                display: block;
                .kv-box-container{
                    padding-right: 1.5rem;
                    padding-left: 1.5rem;
                    margin-right: auto;
                    margin-left: auto;
                    box-sizing: border-box;
                    @media screen and (min-width: 768px) {
                        width: 75rem;
                    }
                    @media screen and (min-width: 992px) {
                        width: 97rem;
                    }
                    @media screen and (min-width: 1200px) {
                        width: 117rem;
                    }
                    .kv-box{
                        padding: 3rem;
                        background-color: rgba(44,62,80,.8);
                        float: left;
                        box-sizing: border-box;
                        @media screen and (min-width: 992px) {
                            width: 50%;
                        }
                        .title{
                            font-size: 4.2rem;
                            line-height: 5.8rem;
                            margin-bottom: 2.5rem;
                            color:#fff;
                            /*font-family: SST W20 Medium,Verdana,sans-serif;*/
                            font-weight: 500;
                        }
                        .description{
                            font-size: 2rem;
                            line-height: 2.6rem;
                            margin-bottom: 2.5rem;
                            color:#fff;
                            /*font-family: SST W20 Light,Verdana,sans-serif;*/
                            font-weight: 500;
                        }
                        .link{
                            padding-top: 2.5rem;
                            div{
                                box-sizing: border-box;
                                display: inline-block;
                                background-color: transparent;
                                border: 1px solid #f3f6fb;
                                border-radius: 0;
                                -webkit-box-shadow: none!important;
                                box-shadow: none!important;
                                color: #f3f6fb;
                                /*font-family: SST W20 Medium,Verdana,sans-serif;*/
                                font-size: 1.6rem;
                                margin-right: 1.6rem;
                                margin-bottom: 2.5rem;
                                min-width: 12rem;
                                padding: 1.2rem 3rem;
                                text-align: center;
                                -webkit-transition: all .25s ease;
                                transition: all .25s ease;
                                &:hover{
                                    background-color: #f3f6fb;
                                    color: #2c3e50;
                                }
                            }
                        }
                    }
                }
            }
        }
        .swiper-pagination{
            @media screen and (min-width: 1024px) {
                display: none!important;
            }
        }
        .swiper-button-disabled{
            opacity: 0!important;
        }
        .swiper-button-prev{
            left: 2.5rem;
            background-color: #282c36;
            opacity: .7;
            overflow: hidden;
            position: absolute;
            transition: opacity .3s,visibility .3s;
            visibility: visible;
            width: 6.1rem;
            z-index: 1;
            border: 1px solid #282c36;
            top: 50%;
            margin-top: -5.15rem;
            height: 10.3rem;
            cursor: pointer;
            &:hover{
                opacity: 1;
                filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" /></filter></svg>#filter');
                -webkit-filter: grayscale(0);
                filter: grayscale(0);
                border: 1px solid hsla(0,0%,85%,.8);
            }
            &::before{
                font-size: 4.8rem;
                line-height: 4.8rem;
                padding-top: 2.7rem;
                color: #fff;
                content: "x";
                text-indent: 0;
                font-family: sonyngp!important;
                display: block;
                text-align: center;
            }
            @media screen and (max-width: 1024px) {
                display: none !important;
            }
        }

        .swiper-button-next{
            right: 2.5rem;
            background-color: #282c36;
            opacity: .7;
            overflow: hidden;
            position: absolute;
            transition: opacity .3s,visibility .3s;
            visibility: visible;
            width: 6.1rem;
            z-index: 1;
            border: 1px solid #282c36;
            top: 50%;
            margin-top: -5.15rem;
            height: 10.3rem;
            cursor: pointer;
            &:hover{
                opacity: 1;
                filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" /></filter></svg>#filter');
                -webkit-filter: grayscale(0);
                filter: grayscale(0);
                border: 1px solid hsla(0,0%,85%,.8);
            }
            &::before{
                font-size: 4.8rem;
                line-height: 4.8rem;
                padding-top: 2.7rem;
                color: #fff;
                content: "y";
                text-indent: 0;
                font-family: sonyngp!important;
                display: block;
                text-align: center;
            }
            @media screen and (max-width: 1024px) {
                display: none !important;
            }
        }
    }
}
.news-carousel{
    /*padding-bottom:6rem;*/
    position: relative;
    @media screen and (max-width: 768px) {
        width:98% !important;
    }
    .swiper{
        /*width:95.6%;*/
        /*height:55.9rem;*/
        padding-top:2rem;
        /*padding-bottom:2rem;*/
        .swiper-wrapper{
            width:100%;
            /*height: 50rem;*/
            .newsitem{
                height:46.68rem;
                border: .16rem solid #e9e9e9;
                position: relative;
                margin: 0 auto .8rem auto;
                z-index: 1;
                width:100%;
                box-sizing: border-box;
                @media screen and (min-width: 768px) {
                    width:34.2rem !important;
                }
                @media screen and (min-width: 992px) {
                    width: 30.2rem !important;
                }
                @media screen and (min-width: 1200px) {
                    width:33.28rem !important;
                }
                &:hover{
                    &:before{
                        opacity: 0.75!important;
                    }
                }
                &::before{
                    background-color: hsla(0,0%,85%,.8);
                    content: "";
                    display: block;
                    height: 100%;
                    left: 0;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    -webkit-transition: opacity .5s ease;
                    transition: opacity .5s ease;
                    width: 100%;
                    z-index: 2;
                    cursor: pointer;
                }
                a{
                    display: block;
                    width:100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: top;
                    .title{
                        box-sizing: border-box;
                        width: 100%;
                        height: 13rem;
                        padding: 1.5rem 2rem;
                        position: absolute;
                        bottom:0;
                        background: #fff;
                        padding-bottom: 1.5rem;
                        line-height: 2.88rem;
                        font-size: 1.8rem;
                        color: #000;
                        font-weight: 700;
                        /*font-family: "SST W20 Medium";*/
                        span{
                            display: block;
                            color:#333;
                            /*font-family: "SST W20 Light";*/
                        }
                    }
                    .tag{
                        position: absolute;
                        bottom: 0;
                        padding-bottom: 1rem;
                        left: 2rem;
                        font-size: 1.6rem;
                        color: #000;
                        font-weight: 500;
                        /*font-family: 'SST W20 MEDIUM';*/
                        color:#0a5e9d;
                    }
                }
            }
        }
    }
    .swiper-button-prev-clone,.swiper-button-next-clone{
        width:6.1rem;
        height: 10.3rem;
        position: absolute;
        cursor: pointer;
        height: 10.3rem;
        margin-top: -5.15rem;
        opacity: .7;
        overflow: hidden;
        top: 45.5%;
        -webkit-transition: opacity .3s,visibility .3s;
        transition: opacity .3s,visibility .3s;
        visibility: visible;
        width: 6.1rem;
        z-index: 1;
        &::before{
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            font-family: sonyngp!important;
            line-height: 10.3rem;
            font-size: 3.0rem;
            text-align: center;
            text-indent: 0;
            color: #0a5e9d;
        }
        &.swiper-button-disabled{
            opacity: 0;
        }
    }
    .swiper-button-prev-clone{
        left:-2rem;
        &::before{
            content:'x';
        }
        @media screen and (min-width: 992px) {
            left:-5rem;
        }
    }
    .swiper-button-next-clone{
        right:-2rem;
        &::before{
            content:'y';
        }
        @media screen and (min-width: 992px) {
            right:-5rem;
        }
    }
}
.products-carousel{
    /*padding-bottom:6rem;*/
    position: relative;
    @media screen and (max-width: 768px) {
        width: 98%;
    }
    .swiper{
        /*width:95.6%;*/
        /*min-height:26rem;*/
        padding-top:2rem;
        /*padding-bottom:2rem;*/
        .swiper-wrapper{
            /*width:100%;*/
            /*min-height:26rem;*/
            box-sizing: border-box;
            .swiper-slide{
                padding-left: 1px;
                padding-right: 1px;
                box-sizing: border-box;
                @media screen and (max-width: 768px) {
                    /*margin-left:.4rem;
                    margin-right: .4rem;*/
                }
                .productitem{
                    width:22.4rem;
                    margin-left:.2rem;
                    margin-right:.2rem;
                    width:100%;
                    box-sizing: border-box;
                    @media screen and (max-width: 768px) {
                        margin-left:0rem;
                        margin-right:0rem;
                    }
                    @media screen and (min-width: 768px) {
                        width:17.6rem !important;
                    }
                    @media screen and (min-width: 992px) {
                        width:23.1rem !important;
                    }
                    @media screen and (min-width: 1024px) {
                        width: 18.4rem !important;
                    }
                    @media screen and (min-width: 1200px) {
                        width:22.4rem !important;
                    }
                    div{
                        border: 1px solid #e1f0f5;
                        margin-bottom: 1.5rem;
                        box-sizing: border-box;
                        img{
                            width: 100%;
                            display: block;
                        }
                    }

                    h3{
                        color: #000;
                        margin-bottom: 5px;
                        max-width: 95%;
                        font-weight: bold;
                    }
                    p{
                        color: #333;
                        line-height: 2.4rem;
                        max-width: 95%;
                        word-wrap: break-word;
                        margin-bottom:0;
                        margin-top:0;
                        font-weight: 700;
                        font-size: 1.5rem;
                    }
                }
            }
        }
        .swiper-pagination{
            @media screen and (min-width: 1024px) {
                display: none!important;
            }
        }
    }
    .swiper-button-prev-clone,.swiper-button-next-clone{
        width:6.1rem;
        height: 10.3rem;
        position: absolute;
        cursor: pointer;
        height: 10.3rem;
        margin-top: -5.15rem;
        opacity: .7;
        overflow: hidden;
        top: 31.5%;
        -webkit-transition: opacity .3s,visibility .3s;
        transition: opacity .3s,visibility .3s;
        visibility: visible;
        width: 6.1rem;
        z-index: 1;
        &::before{
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            font-family: sonyngp!important;
            line-height: 10.3rem;
            font-size: 3.0rem;
            text-align: center;
            text-indent: 0;
            color: #0a5e9d;
        }
        &.swiper-button-disabled{
            opacity: 0;
        }
    }
    .swiper-button-prev-clone{
        left:-8rem;
        &::before{
            content:'x';
        }
    }
    .swiper-button-next-clone{
        right:-8rem;
        &::before{
            content:'y';
        }
    }
}
.press-carousel{
    padding-bottom:6rem;
    position: relative;
    @media screen and (max-width: 425px) {
        padding-bottom:1rem;
    }
    .swiper{
        /*width:95.6%;*/
        /*height:19.9rem;*/
        padding-top:2rem;
        padding-bottom:2rem;
        .swiper-wrapper{
            /*width:100%;*/
            /*height:15rem;*/
            /*margin-left:1.5rem;
            margin-right:1.5rem;*/
            .swiper-slide{
                .pressitem{
                    outline: none;
                    width: 95%;
                    margin-left:1.5rem;
                    margin-right:1.5rem;
                    @media screen and (min-width: 640px) {
                        width: 98%;
                    }
                    @media screen and (min-width: 1200px) {
                        width: 25.5rem;
                    }
                    a{
                        box-sizing: border-box;
                        background: #fff;
                        color: #e1f0f5;
                        display: block;
                        height: 16rem;
                        width: 100%;
                        overflow: hidden;
                        padding:1.5rem 2rem 2rem 2rem;
                        -webkit-transition: background-color .5s ease;
                        transition: background-color .5s ease;
                        .date{
                            color: #333;
                            /*font-family: SST W20 Medium,Verdana,sans-serif;*/
                            font-size: 1.6rem;
                            font-weight: 700;
                        }
                        .description{
                            color: #000;
                            font-size: 1.6rem;
                            line-height: 2.4rem;
                            font-weight: 700;
                            /*font-family: SST W20 Light,Verdana,sans-serif;*/
                            margin-bottom: .8rem;
                            margin-top: .4rem;
                            height: 7.2rem;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                        .linkName{
                            color: #0a5e9d;
                            margin-bottom: 0;
                            font-size: 1.6rem;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
    .swiper-button-prev-clone,.swiper-button-next-clone{
        width:6.1rem;
        height: 10.3rem;
        position: absolute;
        cursor: pointer;
        height: 10.3rem;
        margin-top: -5.15rem;
        opacity: .7;
        overflow: hidden;
        top: 31.5%;
        -webkit-transition: opacity .3s,visibility .3s;
        transition: opacity .3s,visibility .3s;
        visibility: visible;
        width: 6.1rem;
        z-index: 1;
        &::before{
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            font-family: sonyngp!important;
            line-height: 10.3rem;
            font-size: 3.0rem;
            text-align: center;
            text-indent: 0;
            color: #0a5e9d;
        }
        &.swiper-button-disabled{
            opacity: 0;
        }
    }
    .swiper-button-prev-clone{
        left:-8rem;
        &::before{
            content:'x';
        }
    }
    .swiper-button-next-clone{
        right:-8rem;
        &::before{
            content:'y';
        }
    }
}
.carousel.common-carousel{
    width:100%!important;
    .swiper-button-prev-clone,.swiper-button-next-clone{
        width:6.1rem;
        height: 10.3rem;
        position: absolute;
        cursor: pointer;
        height: 10.3rem;
        margin-top: -5.15rem;
        opacity: .7;
        overflow: hidden;
        top: 31.5%;
        -webkit-transition: opacity .3s,visibility .3s;
        transition: opacity .3s,visibility .3s;
        visibility: visible;
        width: 6.1rem;
        z-index: 1;
        &::before{
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            font-family: sonyngp!important;
            line-height: 10.3rem;
            font-size: 3.0rem;
            text-align: center;
            text-indent: 0;
            color: #0a5e9d;
        }
        &.swiper-button-disabled{
            opacity: 0;
        }
    }
    .swiper-button-prev-clone{
        left:0;
        top: 50%;
        margin: -5.15rem 0 0 0;
        &::before{
            content:'x';
        }
    }
    .swiper-button-next-clone{
        right:0;
        top: 50%;
        margin: -5.15rem 0 0 0;
        &::before{
            content:'y';
        }
    }
}
